/* Article */

@custom-media --h-mobile-viewport (max-height: 414px) and (max-width: 1024px);
@custom-media --h-desktop-viewport (min-height: 720px) and (min-width: 1280px);
@custom-media --xs-viewport (min-width: 376px);
@custom-media --sm-viewport (min-width: 480px);
@custom-media --md-viewport (min-width: 860px);
@custom-media --lg-viewport (min-width: 1024px);
@custom-media --xlg-viewport (min-width: 1280px);
@custom-media --2xlg-viewport (min-width: 1920px);

.RS-article {
  display: flex;
  /* justify-content: space-between; */
  width: 100%;
  max-width: var(--RS-col-6);
  /* text-align: left; */
  align-items: stretch;
  justify-content: center;
  flex-direction: column;
  

  @media (--lg-viewport) {
  }
}

.RS-article--centered{
  margin: 0 auto;
  align-items: center;
  justify-content: center;
}

.RS-article--block{
  display: block;
}

.RS-article--cols{
  gap: var(--RS-space-24);
  align-items: center;

  @media (--lg-viewport) {
    flex-direction: row;
    gap: var(--RS-space-96);
  }
}

.RS-article--reverse {
  @media (--lg-viewport) {
    flex-direction: row-reverse;
  }
}


.RS-article--expert{
  /* border: var(--RS-space-1) solid var(--RS-light-grey-color); */
  padding: 0 var(--RS-space-24) var(--RS-space-32) var(--RS-space-24);
  border-radius: var(--RS-space-32);
  flex-direction: column-reverse;
  gap: 0;
  background-color: var(--RS-accent-color-secondary);

  @media (--lg-viewport) {
    flex-direction: row;
    padding: 0 var(--RS-space-48) var(--RS-space-24) var(--RS-space-48);
    gap: var(--RS-space-96);
  }
}

.RS-article--expert.RS-article--reverse {
  background-color: var(--RS-accent-color-primary);

  @media (--lg-viewport) {
    flex-direction: row-reverse;
  }
}


.RS-article--wide{
  
  max-width: 100%;

  @media (--lg-viewport) {
    max-width: var(--RS-col-8);
  }
}

.RS-article--hero{
  gap: var(--RS-space-24);
  align-items: center;

  @media (--lg-viewport) {
    flex-direction: row;
    gap: var(--RS-space-96);
    gap: var(--RS-space-64);
  }
}


.RS-article--hero > header{
  width: 100%;
  
  margin: 0 auto;
  text-align: center;


  @media (--lg-viewport) {
    width: 50%;
    width: calc(50% - var(--RS-space-64));
    width: calc(50% - var(--RS-space-96));
    width: calc(50% - var(--RS-space-32));
    margin: 0;
    text-align: left;
  }
}

.RS-article--hero > .RS-content{
  width: 100%;

  @media (--lg-viewport) {
    width: 50%;
    width: calc(50% + var(--RS-space-64));
    width: calc(50% + var(--RS-space-96));
    width: calc(50% + var(--RS-space-32));
  }
}

.RS-article > svg {
  height: auto;
  margin: 0 0 var(--RS-space-48);

  @media (--md-viewport) {
    margin: 0 0 var(--RS-space-64);
  }

  @media (--lg-viewport) {
    margin: 0;
  }
}

.RS-article + .RS-article{
  margin-top: var(--RS-space-48);

  @media (--md-viewport) {
    margin-top: var(--RS-space-64);
  }
}

.RS-article--expert + .RS-article--expert{
  margin-top: var(--RS-space-64);

  @media (--md-viewport) {
    margin-top: var(--RS-space-64);
  }
}
