/* Top Bar */

/* 
Todo: Parcel as of now does not import @custom-media rules from any other files and fails to build if @custom-media is not defined explicitly in every CSS file that makes use of it, the below is a test to confirm, other rules get imported
@import url("custom-media.css");
 */

@custom-media --h-mobile-viewport (max-height: 414px) and (max-width: 1024px);
@custom-media --h-desktop-viewport (min-height: 720px) and (min-width: 1280px);
@custom-media --xs-viewport (min-width: 376px);
@custom-media --sm-viewport (min-width: 480px);
@custom-media --md-viewport (min-width: 860px);
@custom-media --lg-viewport (min-width: 1024px);
@custom-media --xlg-viewport (min-width: 1280px);
@custom-media --2xlg-viewport (min-width: 1920px);

.RS-top-bar {
  position: fixed;
  top: 0;
  right: 0;
  bottom: auto;
  left: 0;
  z-index: 666;
  width: 100%;
  padding: var(--RS-space-24);
  transition: transform var(--RS-anim-duration-lg) var(--RS-default-transition);
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  /* backdrop-filter: blur(1px);
  -webkit-backdrop-filter: blur(1px); */

  @media (--md-viewport) {
    padding: var(--RS-space-24) var(--RS-space-32);
   
  }
}

.RS-top-bar:before{
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: inherit;
  height: 100%;
  /* background-image: linear-gradient(180deg, rgba(16, 15, 15,.89) 0%, rgba(16, 15, 15,.34) 67%, rgba(16, 15, 15,0) 100%); */
  z-index: -1;
  background: var(--RS-white-color);
  opacity: 1;
  mask-image: linear-gradient(180deg, rgba(0, 0, 0, 1), transparent 100%);
}

.RS-top-bar--hidden {
  transform: translate3d(0, -100%, 0);
}

.RS-top-bar__container {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  max-width: var(--RS-col-8);
  height: 32px;
  margin: 0 auto;
}

.RS-top-bar__brand{
  z-index: 1;
}

.RS-top-bar__logo {
  width: calc(var(--RS-space-128) - var(--RS-space-8));
  height: calc(var(--RS-space-32) - var(--RS-space-2));
  margin: 0 var(--RS-space-8) 0 0;

  @media (--md-viewport) {
    width: var(--RS-space-128);
    height: calc(var(--RS-space-32) + 0px);
  }
}

.RS-top-bar__nav {
    height: 100vh;
    width: 100vw;
    background: var(--RS-white-color);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: none;
    position: fixed;
    top: var(--RS-space-24-n);
    left: var(--RS-space-24-n);
    gap: var(--RS-space-48);
    transition: transform var(--RS-anim-duration-lg) var(--RS-default-transition);
    transform: translateX(calc(-100% - var(--RS-space-48)));
    font-size: var(--RS-font-size-mobile-nav);

  @media (--md-viewport) {
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    height: inherit;
    gap: calc(var(--RS-space-32) + var(--RS-space-4));
    width: unset;
    font-size: inherit;
    background: unset;
    position: relative;
    top: unset;
    left: unset;
    margin-left: auto;
    transform: none;
    font-size: var(--RS-font-size-top-nav);
  }
}

.RS-top-bar__nav:after{
  content: '';
  display: block;
  position: absolute;
  right: var(--RS-space-48-n);
  top: 0;
  width: inherit;
  height: 100%;
  width: var(--RS-space-48);
  border-radius: 0 50% 50% 0;
  background: inherit;
}

.RS-top-bar__nav--displayed{
  display: flex;
}

.RS-top-bar__nav--opened{
  transform: translateX(0%);
}

.RS-top-bar__nav > .RS-link{
  padding-bottom: var(--RS-space-4);
  opacity: 0;
  transform: translateY(var(--RS-space-32-n));
  transition: transform var(--RS-anim-duration-md) var(--RS-default-transition), opacity var(--RS-anim-duration-md) var(--RS-default-transition);
  /* font-weight: var(--RS-font-weight-variable-bold); */
  letter-spacing: .25px;
  

  @media (--md-viewport) {
    opacity: 1;
    transform: none;
    font-weight: var(--RS-font-weight-variable-bold);
  }
}

.RS-top-bar__nav > .RS-link--visible{
  opacity: 1;
  transform: none;
}

.RS-top-bar__nav > .RS-link--mobile{
  display: initial;

  @media (--md-viewport) {
    display: none;
  }
}

.RS-top-bar__nav > .RS-link:nth-of-type(1){
  transition-delay: calc(var(--RS-anim-delay)*1);
  @media (--md-viewport) {
    transition-delay: unset;
  }
}

.RS-top-bar__nav > .RS-link:nth-of-type(2){
  transition-delay: calc(var(--RS-anim-delay)*2);
  @media (--md-viewport) {
    transition-delay: unset;
  }
}

.RS-top-bar__nav > .RS-link:nth-of-type(3){
  transition-delay: calc(var(--RS-anim-delay)*3);
  @media (--md-viewport) {
    transition-delay: unset;
  }
}

.RS-top-bar__nav > .RS-link:nth-of-type(4){
  transition-delay: calc(var(--RS-anim-delay)*4);
  @media (--md-viewport) {
    transition-delay: unset;
  }
}

.RS-top-bar__nav > .RS-link:nth-of-type(5){
  transition-delay: calc(var(--RS-anim-delay)*5);
  @media (--md-viewport) {
    transition-delay: unset;
  }
}

.RS-top-bar__nav > .RS-link:nth-of-type(6){
  transition-delay: calc(var(--RS-anim-delay)*6);
  @media (--md-viewport) {
    transition-delay: unset;
  }
}

.RS-top-bar__nav > .RS-link:nth-of-type(7){
  transition-delay: calc(var(--RS-anim-delay)*7);
  @media (--md-viewport) {
    transition-delay: unset;
  }
}

.RS-link__badge{
  font-size: var(--RS-font-size-disclaimer);
  letter-spacing: 0;
  font-weight: var(--RS-font-weight-variable-regular);
  padding: var(--RS-space-4) var(--RS-space-12);
  border-radius: var(--RS-space-16);
  background: var(--RS-black-color);
  color: var(--RS-white-color); 
  margin-left: var(--RS-space-4);
  opacity: .21;
}

.RS-top-bar__mobile-nav-icon {
  display: block;
  margin-left: auto;
  z-index: 1;
  -webkit-tap-highlight-color: transparent;
  flex: 1;
  
  @media (--md-viewport) {
    display: none;
  }
}

.RS-top-bar__mobile-nav-icon span{
  width: calc(var(--RS-space-32) - var(--RS-space-2));
  height: calc(var(--RS-space-2) + var(--RS-space-1));
  background-color: var(--RS-brand-color);
  display: block;
  margin: var(--RS-space-4) 0 var(--RS-space-4) auto;
  transition: transform var(--RS-anim-duration-md) var(--RS-default-transition);
  transform-origin: center;
  border-radius: var(--RS-space-12);
}

.RS-top-bar__mobile-nav-icon--active span:nth-of-type(1){
  transform: rotateZ(45deg) translateY(calc(var(--RS-space-4) + var(--RS-space-1)));
}


.RS-top-bar__mobile-nav-icon span:nth-of-type(2){
  /* transform: translateX(var(--RS-space-6-n)); */
}

.RS-top-bar__mobile-nav-icon--active span:nth-of-type(2){
  transform: rotateZ(-45deg) translateY(calc(var(--RS-space-4-n) - var(--RS-space-1)));
}


/* @keyframes showTopBar {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
} */

.RS-top-bar__nav,
.RS-top-bar__dummy{
flex: 1;
}

.RS-top-bar__floating-pill{
  background-color: var(--RS-graphite-color);
  padding: calc(var(--RS-space-6) + var(--RS-space-1)) var(--RS-space-16);
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--RS-white-color);
  font-size: var(--RS-font-size-disclaimer);
  width: fit-content;
  margin: calc(var(--RS-space-12) - var(--RS-space-6)) auto 0 auto;
  border-radius: var(--RS-space-16);
  gap: var(--RS-space-4);

  /* @media (--md-viewport) {
    margin: var(--RS-space-12) auto 0 auto;
  } */

  @media (--xs-viewport) {
    margin: calc(var(--RS-space-12) - var(--RS-space-2)) auto 0 auto;
  }

  @media (--md-viewport) {
    margin: var(--RS-space-12) auto 0 auto;
  }


  @media (--lg-viewport) {
    font-size: var(--RS-font-size-disclaimer-desktop);
    padding: var(--RS-space-8) var(--RS-space-24);
  }
}

.RS-top-bar__floating-pill:before{
  content: '';
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23000000' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.7878 3.10215C11.283 2.09877 12.7138 2.09876 13.209 3.10215L15.567 7.87987L20.8395 8.64601C21.9468 8.80691 22.3889 10.1677 21.5877 10.9487L17.7724 14.6676L18.6731 19.9189C18.8622 21.0217 17.7047 21.8627 16.7143 21.342L11.9984 18.8627L7.28252 21.342C6.29213 21.8627 5.13459 21.0217 5.32374 19.9189L6.2244 14.6676L2.40916 10.9487C1.60791 10.1677 2.05005 8.80691 3.15735 8.64601L8.42988 7.87987L10.7878 3.10215Z' fill='%23ffe9ca'/%3E%3C/svg%3E%0A");
  background-size: contain;
  display: inline-block;
  width: calc(var(--RS-space-12) - var(--RS-space-1));
  height: calc(var(--RS-space-12) - var(--RS-space-1));
  margin-right: var(--RS-space-1);
  /* transform: translateY(-5%); */

  @media (--lg-viewport) {
    width: calc(var(--RS-space-12) + var(--RS-space-1));
    height: calc(var(--RS-space-12) + var(--RS-space-1));
    margin-right: var(--RS-space-2);
    transform: translateY(-5%);
  }
}

.RS-top-bar__floating-pill .RS-link:before{
  transform: scale(1);
  background-color: var(--RS-accent-color-secondary);
  bottom: var(--RS-space-1);
  height: var(--RS-space-1);
}